import { Box, Card, CardContent, Grid, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const cardsData = [
  "working in industries related to science, technology, digital products, and services",
  "invested in gender equality at your company, ecosystem and society at large",
  "looking for a global social impact, diversity and outreach",
]

const Highlight = ({ children }) => (
  <Typography variant="body2" component="span" color="primary">
    {children}
  </Typography>
)

const AboutJobSlideTwo = () => {
  return (
    <SectionWrapper>
      <Typography variant="body2" gutterBottom>
        We have created a <Highlight>platform</Highlight> to connect young women
        to job and learning opportunities in the digital and tech economy and to
        help them successfully transition from learning skills to landing a job
      </Typography>
      <Typography variant="body2">
        We are looking for companies and organizations like you to join us in
        this mission. We are a fit, if you are:
      </Typography>
      <Box pt={4}>
        <Grid container spacing={2}>
          {cardsData.map(item => (
            <Grid item xs={12} md={4}>
              <Card style={{ height: "100%" }}>
                <CardContent>{item}</CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </SectionWrapper>
  )
}

export default AboutJobSlideTwo