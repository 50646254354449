import { Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const AboutJobSlideOne = () => {
  return (
    <SectionWrapper light>
      <Typography variant="h2" component="p">
        COVID-19 pandemic has flattened a lot of young women’s dreams,
        ambitions and opportunities. While tech and digital are the new drivers
        of economy, women remain at the margins of this economy.
      </Typography>
      <Typography variant="h2" component="p" color="primary">
        Let’s join forces to prevent another “lost generation” and help young
        women join the tech workforce NOW.
      </Typography>
      {/* <Typography variant="h4">
        Tech and digital are the new drivers of economy but women are at the
        margins
      </Typography>
      <Typography variant="h5">
        Let’s join forces to prevent another “lost generation” and help young
        women join the tech workforce NOW
      </Typography> */}
    </SectionWrapper>
  )

}

export default AboutJobSlideOne