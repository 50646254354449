import { Box, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const blocksData = [
  {
    title: "jobs",
    detail:
      "post jobs for greater visibility for women interested in STEM careers, especially entry-level job targeting youth transitioning from completion of education to entering the workforce, financial independence and building a career",
    image: (
      <StaticImage
        src="../../images/jobfair_1.jpg"
        alt="job fair 1"
        placeholder="blurred"
      />
    ),
  },
  // {
  //   title: "job shadowing",
  //   detail:
  //     "a one-week opportunity to learn about the various career possibilities in tech and innovation. We encourage partners to also provide “bespoke” opportunities to spend a few days with a leader and shadow a C-suit executive, an ICT minister, an innovation team director, a professor in a top university, start-up founder, etc.",
  //   image: (
  //     <StaticImage
  //       src="../../images/jobfair_2.jpg"
  //       alt="job fair 2"
  //       placeholder="blurred"
  //     />
  //   ),
  // },
  {
    title: "internship",
    detail:
      "a minimum three months engagement for a young person finishing their education, taking a gap year and/or developing professional skills in a field of interest and future path.",
    image: (
      <StaticImage
        src="../../images/jobfair_3.jpg"
        alt="job fair 3"
        placeholder="blurred"
      />
    ),
  },
  {
    title: "challenges and learning",
    detail:
      "provide opportunities to build capacity and knowledge on inclusive innovation and gender-transformative technology, through training, MOOC, hackathons, innovation challenges, etc. that use the Gender x Innovation Guide.",
    image: (
      <StaticImage
        src="../../images/jobfair_2.jpg"
        alt="job fair 2"
        placeholder="blurred"
      />
    ),
  },
]

const AboutJobSlideThree = () => {
  return (
    <SectionWrapper light>
      <Typography variant="body2">
        together we will connect young women to three types of opportunities
      </Typography>
      <Box pt={4}>
        <Grid container spacing={2}>
          {blocksData.map(({ title, detail, image }) => (
            <Grid key={title} item xs={12} md={4}>
              <Card style={{backgroundColor: blueGrey[50], height: "100%"}}>
                <CardMedia>{image}</CardMedia>
                <CardHeader title={title} />
                <CardContent>{detail}</CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </SectionWrapper>
  )
}

export default AboutJobSlideThree