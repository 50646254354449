import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Button,
} from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const blocksData = [
  {
    title: "our commitment",
    detail: (
      <>
        <Typography gutterBottom>
          We are a coalition of 15 partners who are mobilizing our networks to
          connect young women all around the world to opportunities and
          employers in STEM fields.
        </Typography>
        <Typography gutterBottom>
          Our platform will crowdsource opportunities available in the STEM
          field and support young women all around the world through easy access
          and resources to apply and to avail those opportunities.
        </Typography>
        <Typography gutterBottom>
          We have developed the Gender x Innovation Guide to help you create a
          positive innovation culture and help people inside and outside of
          organizations break barriers, come together, generate new ideas, and
          iterate for gender transformative change.
        </Typography>
      </>
    ),
  },
  {
    title: "your impact",
    detail: (
      <>
        <Typography gutterBottom>
          We can help you serve your commitment to inclusion, equality and
          diversity at workplace.
        </Typography>
        <Typography gutterBottom>
          By advertising opportunities on our platform, you are investing in
          sourcing talent for equality.
        </Typography>
        <Typography gutterBottom>
          Making opportunities accessible to underrepresented groups in STEM
          careers such as young women, women of color and from Global South.
        </Typography>
        <Typography gutterBottom>
          By joining forces with us and contributing to this platform you join
          the Generation Equality movement. The largest global movement of
          leaders for gender equality in 25 years.
        </Typography>
      </>
    ),
  },
]

const AboutJobSlideFour = () => {
  return (
    <SectionWrapper>
      <Grid container>
        {blocksData.map(({ title, detail }) => (
          <Grid key={title} item xs={12} md={6}>
            <Card>
              <CardHeader title={title} />
              <CardContent>{detail}</CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* <Button
        variant="outlined"
        href="https://jobs.techforgenerationequality.org/"
      >
        See Job Board here
      </Button> */}
      <Box pt={4} textAlign="center">
        <Button
          variant="contained"
          href="https://jobs.techforgenerationequality.org/post-job/"
          endIcon={<ArrowForwardIcon />}
          disableElevation
          target="_self"
        >
          Post your job here
        </Button>
      </Box>
    </SectionWrapper>
  )
}

export default AboutJobSlideFour
