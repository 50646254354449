import React from "react"
import AboutJobSlideFour from "../components/aboutJobSlides/aboutJobSlideFour"
import AboutJobSlideOne from "../components/aboutJobSlides/aboutJobSlideOne"
import AboutJobSlideThree from "../components/aboutJobSlides/aboutJobSlideThree"
import AboutJobSlideTwo from "../components/aboutJobSlides/aboutJobSlideTwo"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutJobFairPage = ({ pageContext: { originalPath } }) => {
  return (
    <Layout title="About the Job Fair" originalPath={originalPath}>
      <Seo title="About the Job Fair" />
      <AboutJobSlideOne />
      <AboutJobSlideTwo />
      <AboutJobSlideThree />
      <AboutJobSlideFour />
    </Layout>
  )
}

export default AboutJobFairPage